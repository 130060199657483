import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api.js';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = ({ darkMode }) => {
    const [data, setData] = useState({ email: '', otp: '', password: '' });
    const [emailSent, setEmailSent] = useState('');
    const [error, setError] = useState(null);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const otpInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.email) {
            setData((prevData) => ({ ...prevData, email: location.state.email }));
            setEmailSent(location.state.email);
        }
    }, [location.state]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setOtpError(false);
    };

    const verifyOtp = async () => {
        try {
            const response = await api.post('/verify-otp', { email: data.email, otp: data.otp });
            if (response.status === 200) {
                setOtpVerified(true);
                toast.success('OTP verified successfully');
            } else {
                throw new Error('OTP verification failed');
            }
        } catch (error) {
            setOtpVerified(false);
            setOtpError(true);
            otpInputRef.current.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!otpVerified) {
            await verifyOtp();
            return;
        }

        try {
            const response = await api.post('/reset-password', { email: data.email, password: data.password });
            if (response.status === 200) {
                toast.success('Password reset successful');
                navigate('/login');
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className={`flex lg:w-1/2 w-[90%] mx-auto flex-col space-y-4`}>
            <h1 className={`text-2xl text-center pt-4 font-bold`}>Reset Your Password</h1>
            {emailSent && <p className="text-center text-gray-500">OTP has been sent to: <strong>{emailSent}</strong></p>}
            {error && <p className="text-center text-red-500">{error}</p>}

            <input
                name="email"
                type="email"
                onChange={handleChange}
                placeholder="Email"
                value={data.email}
                className="p-2 border rounded"
                required
                disabled
            />
            <div className="relative">
                <input
                    name="otp"
                    onChange={handleChange}
                    placeholder="OTP"
                    className={`p-2 w-full border outline-none rounded ${otpError ? 'border-red-500 text-red-500' : ''},  ${darkMode ? 'text-black' : 'text-black'}`}
                    required
                    ref={otpInputRef}
                />
                {otpVerified ? (
                    <span className="absolute right-3 top-2 text-green-600 font-bold"><FontAwesomeIcon icon={faCheck} /></span>
                ) : otpError ? (
                    <span className="absolute right-3 top-2 text-red-500"> invalid OTP ❌</span>
                ) : null}
            </div>
            <div className="relative">
                <input
                    name="password"
                    type={showPassword ? 'text' : 'password'} // Toggle between text and password
                    onChange={handleChange}
                    placeholder="New Password"
                    className={`p-2 w-full border rounded pr-10 ${darkMode ? 'text-black' : 'text-black'}`}
                    required
                    disabled={!otpVerified}
                />
                <button
                    type="button"
                    className="absolute right-3 top-2 text-gray-500"
                    onClick={() => setShowPassword(!showPassword)} 
                >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
            </div>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                {otpVerified ? 'Reset Password' : 'Verify OTP'}
            </button>
        </form>
    );
};

export default ResetPassword;
