import { useState } from 'react';
import api from '../utils/api.js';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = ({ setToken, darkMode }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Reset error

        try {
            const response = await api.post('/login', formData);
            setToken(response.data.token);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            setFormData({ email: '', password: '' });
            navigate('/dashboard');
        } catch (err) {
            console.error('Login error:', err.response?.data || err.message);
            setError('Login failed. Please check your credentials.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1 className={`text-2xl text-center py-4 font-bold`}>Login</h1>
            <form onSubmit={handleSubmit} className="flex flex-col lg:w-1/2 w-[90%] mx-auto space-y-4">
                <input
                    name="email"
                    type="email"
                    onChange={handleChange}
                    placeholder="Email"
                    className={`p-2 w-full border rounded ${darkMode ? ' text-black' : ' text-black'}`}
                    required
                />
                <div className="relative">
                    <input
                        name="password"
                        type={showPassword ? 'text' : 'password'} // Toggle password visibility
                        onChange={handleChange}
                        placeholder="Password"
                        className={`p-2 w-full border rounded ${darkMode ? ' text-black' : ' text-black'}`}
                        required
                    />
                    <button
                        type="button"
                        className="absolute right-3 top-3 text-gray-500"
                        onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                    >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </button>
                </div>
                <Link className='text-right text-red-600 hover:underline' to='/forgot-password'>Forget Password?</Link>
                {error && <p className="text-red-500">{error}</p>}
                <button type="submit" className="bg-blue-500 text-white p-2 rounded" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>
        </div>
    );
};

export default Login;
