import React from 'react';
import { useLocation } from "react-router-dom";
import AVNetwork from "../assets/img/Av_network.webp";
import Rentoora from "../assets/img/rentoora.webp";
// Sample card data (you might want to import or fetch this from a service)
const allProjects = [
    {
        id: 1,
        image: AVNetwork, // Update with actual paths
        title: "React Project",
        description: "This project uses React for the frontend and includes various features.",
        websiteLink: "https://yourwebsite.com",
        technologies: ["React", "Tailwind CSS", "Node.js", "MongoDB"],
    },
    {
        id: 2,
        image: Rentoora,
        title: "Vite Mern App",
        description: "A MERN stack application showcasing real-time data handling.",
        websiteLink: "https://yourwebsite.com",
        technologies: ["Vite", "Express", "MongoDB", "React"],
    },
    // Add more projects as needed
];

const ProjectPage = ({ darkMode }) => {
    const location = useLocation();
    const { card } = location.state || {};

    return (
        <div className={`${darkMode ? 'bg-gray-900' : 'bg-gray-100'} min-h-screen`}>
            <div className={`${darkMode ? '' : ''} px-6 pb-6 max-w-4xl mx-auto rounded-lg `}>
                {card ? (
                    <div className="flex flex-col md:flex-row">
                        <div className="flex-1 mb-4 md:mb-0 md:mr-4">
                            <img src={card.image} alt={card.title} className="w-full h-auto rounded-md mb-2" />
                            <h1 className={`${darkMode ? 'text-white' : 'text-gray-900'} text-3xl font-bold text-center mb-2`}>{card.title}</h1>
                            <div className="text-center">
                                <a
                                    href={card.websiteLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                                >
                                    Preview Website
                                </a>
                            </div>
                        </div>

                        <div className="flex-1">
                            <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} text-lg mb-4`}>{card.description}</p>
                            <h2 className={`${darkMode ? 'text-gray-200' : 'text-2xl font-semibold text-gray-800'} mb-2`}>Technologies Used</h2>
                            <ul className="list-disc list-inside">
                                {card.technologies && card.technologies.map((tech, index) => (
                                    <li key={index} className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{tech}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1 className={`${darkMode ? 'text-white' : 'text-gray-900'} text-3xl font-bold text-center mb-4`}>All Projects</h1>
                        {allProjects.map((project) => (
                            <div key={project.id} className={`${darkMode ? 'bg-gray-700' : 'bg-white'} mb-6 p-4 rounded-lg shadow-md`}>
                                <h2 className={`${darkMode ? 'text-white' : 'text-xl font-bold'}`}>{project.title}</h2>
                                <img src={project.image} alt={project.title} className="w-full h-auto rounded mb-2" />
                                <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>{project.description}</p>
                                <div className="mt-2">
                                    <h3 className={`${darkMode ? 'text-gray-200' : 'font-semibold'} mb-1`}>Technologies Used:</h3>
                                    <ul className="list-disc list-inside">
                                        {project.technologies.map((tech, index) => (
                                            <li key={index} className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{tech}</li>
                                        ))}
                                    </ul>
                                </div>
                                <a
                                    href={project.websiteLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                                >
                                    Preview Website
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </div>
    );
};


export default ProjectPage;
