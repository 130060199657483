import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import ContactDataPage from './ContactDataPage.jsx'; 
import api from '../utils/api'; 

const Dashboard = ({ onLogout, darkMode }) => {
    const [user, setUser] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [showContacts, setShowContacts] = useState(false);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData)); 
        }
    }, []);

    const fetchContacts = async () => {
        try {
            const response = await api.get('/contacts');
            setContacts(response.data);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    const updateContactsAfterDelete = (contactId) => {
        setContacts(prevContacts => prevContacts.filter(contact => contact._id !== contactId));
    };

    const handleViewContacts = () => {
        fetchContacts();
        setShowContacts(true);
    };

    const handleClose = () => {
        setShowContacts(false);
    };

    if (!user) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-lg">Loading...</p>
            </div>
        ); // Center loading
    }

    return (
        <div className='absolute w-full   top-0 left-0'>
        <div className="flex flex-col">
            <div className="pt-12 h-screen rounded-lg shadow-lg">
                <div className={`flex justify-between px-4 py-6  rounded-b-[20px]  items-center ${darkMode ? 'bg-gray-800/70' : 'bg-gray-400/50'}`}>
                    <h2 className="text-3xl font-bold font-handwriting capitalize">Dashboard</h2>
                    <button 
                        onClick={onLogout} 
                        className="bg-red-500 text-white  px-2 py-1 rounded hover:bg-red-600 transition duration-200"
                    >
                        Logout
                    </button>
                </div>
                 <div className="flex flex-col lg:justify-center p-4 w-full lg:items-center ">
                    <h3 className="text-2xl font-semibold mb-4 font-handwriting">Personal Information</h3>
                    <div className="lg:flex  gap-12">
                        <div className="flex lg:items-center  lg:mb-4 mb-2">
                            <FontAwesomeIcon icon={faUser} className="text-blue-500 mr-2" />
                            <span><strong>Name:</strong> {user.name}</span>
                        </div>
                        <div className="flex items-center lg:mb-4 mb-2">
                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-2" />
                            <span><strong>Email:</strong> {user.email}</span>
                        </div>
                        <div className="flex items-center lg:mb-4">
                            <FontAwesomeIcon icon={faPhone} className="text-blue-500 mr-2" />
                            <span><strong>Mobile No:</strong> {user.mobileNo}</span>
                        </div>
                    </div>
                    
                  
                </div>
                <div className={` mt-12 absolute bottom-0 w-full h-[430px] rounded-t-[20px] ${darkMode ? 'bg-gray-800/70' : 'bg-gray-400/50'}`}>
                    <div className="flex flex-col lg:justify-center lg:items-center px-4 ">
                    <h1 className="text-xl text-center  py-4 font-bold">Action Section!</h1>
                    <button 
                        onClick={handleViewContacts} 
                        className="mt-4 p-2 lg:w-fit bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
                    >
                        View Contacts
                    </button>
                    </div>
                    </div>
            </div>

            {showContacts && (
                <ContactDataPage 
                    contacts={contacts} 
                    onClose={handleClose} 
                    onDelete={updateContactsAfterDelete} 
                />
            )}
        </div>
        </div>
    );
};

export default Dashboard;
