import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mongoose from '../assets/blogsImg/MongoDB-img.webp';
import vite from '../assets/blogsImg/vite-logo.webp';
import tailwind from '../assets/blogsImg/tailwind-Css.webp';
import cloudinary from '../assets/blogsImg/cloudinary-blog-img.webp';
import Footer from '../components/Footer';

const BlogPosts = ({ darkMode }) => {
  const [loading, setLoading] = useState(true); // State to track loading
  const [posts, setPosts] = useState([]);
  const [imgLoading, setImgLoading] = useState([]); // Track image loading state for each post

  useEffect(() => {
    // Simulate fetching posts
    const fetchPosts = async () => {
      await new Promise((resolve) => setTimeout(resolve, 0)); // Simulate network request delay
      const fetchedPosts = [
        {
          id: 1,
          title: 'Getting Started with MongoDB',
          description: 'An introduction to MongoDB, its features, and how to set it up for your projects.',
          image: mongoose,
          postname: 'Bhishan Sah',
        },
        {
          id: 2,
          title: 'Why Use React with Vite?',
          description: 'Exploring the benefits of using Vite for React development and how it enhances productivity.',
          image: vite,
          postname: 'Bhishan Sah',
        },
        {
          id: 3,
          title: 'Mastering Tailwind CSS',
          description: 'A comprehensive guide to using Tailwind CSS to build beautiful and responsive web designs.',
          image: tailwind,
          postname: 'Bhishan Sah',
        },
        {
          id: 4,
          title: 'Using Cloudinary for Image Management',
          description: 'Learn how to integrate Cloudinary into your application for efficient image storage and manipulation.',
          image: cloudinary,
          postname: 'Bhishan Sah',
        },
      ];
      setPosts(fetchedPosts);
      setImgLoading(Array(fetchedPosts.length).fill(true)); // Initialize loading state for each image
      setLoading(false); // Set loading to false after posts are fetched
    };

    fetchPosts();
  }, []);

  const handleImageLoad = (index) => {
    setImgLoading((prev) => {
      const newLoading = [...prev];
      newLoading[index] = false; // Set specific image to loaded
      return newLoading;
    });
  };

  // Loading sketch placeholder
  const LoadingSketch = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {[...Array(4)].map((_, index) => (
        <article key={index} className={`rounded-lg shadow-lg overflow-hidden bg-gray-300 animate-pulse`}>
          <div className="h-48 bg-gray-400" />
          <div className="p-4">
            <div className="h-6 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 w-1/2 " />
          </div>
        </article>
      ))}
    </div>
  );

  return (
    <main className={`container ${darkMode ? 'text-white' : 'text-gray-900'}`}>
      <h1 className="text-3xl font-bold text-center mb-6">Tech News</h1>
      <div className="lg:w-3/4 pb-6 w-[85%] mx-auto">
        {loading ? (
          <LoadingSketch />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.map((post, index) => (
              <article key={post.id} className={`rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 ${darkMode ? 'bg-black/50 shadow-white/10' : 'bg-white'}`}>
                {imgLoading[index] && (
                  <div className="h-48 bg-gray-400 animate-pulse" /> // Placeholder while loading
                )}
                <img
                  src={post.image}
                  alt={post.title}
                  className={`w-full h-48 object-fit transition-opacity duration-300 ${imgLoading[index] ? 'opacity-0' : 'opacity-100'}`}
                  onLoad={() => handleImageLoad(index)} // Image loaded
                  onError={() => handleImageLoad(index)} // Handle error (optional)
                />
                <div className="p-4">
                  <h2 className="text-xl font-semibold hover:text-blue-500 transition-colors">{post.title}</h2>
                  <p className="mt-2">{post.description}</p>
                  <p className="text-blue-500/50">By : - {post.postname}</p>
                  <Link to={`/single-blog-view/${post.id}`} className="text-blue-600 hover:underline mt-3 inline-block">
                    Read More
                  </Link>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default BlogPosts;
