import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api.js';
import toast from 'react-hot-toast';

const ForgotPassword = ({ darkMode}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await api.post('/forgot-password', { email });

            if (response.status === 200) {
                toast.success(`OTP sent to your email: ${email}`);
                navigate('/reset-password', { state: { email } }); // Pass email to ResetPassword
            }
        } catch (err) {
            setError('User not found. Please check your email and try again.');
            toast.error('User not found. Please check your email and try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1 className={`text-2xl text-center py-4 font-bold`}>Forgot your Password</h1>
            <form onSubmit={handleSubmit} className="flex w-[90%] lg:w-1/2 mx-auto flex-col space-y-4">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className={`p-2 border rounded ${darkMode ? 'text-black' : 'text-black' }`}
                    required
                />
                {error && <p className="text-red-500 text-center">{error}</p>}
                <button
                    type="submit"
                    className={`bg-blue-500 text-white p-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Sending OTP...' : 'Send OTP'}
                </button>
            </form>
        </div>
    );
};

export default ForgotPassword;
