import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProjectPage from './pages/ProjectPage';
import ContactPage from './pages/ContactPage';
import Navbar from './components/NavBar';
import BlogPosts from './pages/BlogPosts';
import SinglePost from './pages/SinglePost';
// import Register from './authPages/Register';
import Login from './authPages/Login';
import ForgotPassword from './authPages/ForgotPassword';
import ResetPassword from './authPages/ResetPassword';
import Dashboard from './authPages/Dashboard';
import api from './utils/api.js';
import { Toaster } from 'react-hot-toast';
import NotFound from './components/NotFound.jsx';

function App() {
    const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode);
        document.body.classList.toggle('dark', darkMode);
    }, [darkMode]);

    useEffect(() => {
        const fetchUser = async () => {
            if (token) {
                try {
                    const response = await api.get('/user/me');
                    setUser(response.data);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    // Handle error accordingly (e.g., logout if token is invalid)
                    setToken(null);
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                }
            }
        };
        fetchUser();
    }, [token]);

    const handleLogout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    };

    return (
        <Router>
            <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
                <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
                <div className="pt-20">
                    <Routes>
                        <Route path="/" element={<HomePage darkMode={darkMode} />} />
                        <Route path="/about" element={<AboutPage darkMode={darkMode} />} />
                        <Route path="/projects" element={<ProjectPage darkMode={darkMode} />} />
                        <Route path="/my-blogs" element={<BlogPosts darkMode={darkMode} />} />
                        <Route path="/contact" element={<ContactPage darkMode={darkMode} />} />
                        <Route path="/single-blog-view/:id" element={<SinglePost darkMode={darkMode} />} />
                        {/* <Route path="/register" element={<Register />} /> */}
                        <Route path="/login" element={token ? <Navigate to="/dashboard" replace /> : <Login setToken={setToken} />} />
                        <Route path="/forgot-password" element={<ForgotPassword darkMode={darkMode}/>} />
                        <Route path="/reset-password" element={<ResetPassword darkMode={darkMode}/>} />
                        <Route path="/dashboard" element={token ? <Dashboard darkMode={darkMode} onLogout={handleLogout} /> : <Navigate to="/login" />} />
                        <Route path="/page-not-found" element={<NotFound darkMode={darkMode} />} />
                        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
                    </Routes>
                </div>

                <Toaster 
                    position="bottom-center"
                    toastOptions={{
                        duration: 3000, // duration of the toast
                        style: {
                            background: '#363636',
                            color: '#fff',
                            fontSize: '22px',
                        },
                    }}
                />
            </div>
        </Router>
    );
}

export default App;
