// src/SinglePost.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import mongoose from '../assets/blogsImg/MongoDB-img.webp';
import vite from '../assets/blogsImg/vite-logo.webp';
import tailwind from '../assets/blogsImg/tailwind-Css.webp';
import cloudinary from '../assets/blogsImg/cloudinary-blog-img.webp';

const posts = [
  {
    id: 1,
    title: 'Getting Started with MongoDB',
    content: 'Detailed content about MongoDB...',
    image: mongoose,
    postname: 'Bhishan Sah',
  },
  {
    id: 2,
    title: 'Why Use React with Vite?',
    content: 'Detailed content about React with Vite...',
    image: vite,
    postname: 'Bhishan Sah',
  },
  {
    id: 3,
    title: 'Mastering Tailwind CSS',
    content: 'Detailed content about Tailwind CSS...',
    image: tailwind,
    postname: 'Bhishan Sah',
  },
  {
    id: 4,
    title: 'Using Cloudinary for Image Management',
    content: 'Detailed content about Cloudinary...',
    image: cloudinary,
    postname: 'Bhishan Sah',
  }
];

const SinglePost = ({ darkMode }) => {
  const { id } = useParams();
  const post = posts.find((post) => post.id === parseInt(id));

  return (
    <div className={`container mx-auto p-5 ${darkMode ? ' text-white' : ' text-gray-900'}`}>
      {post ? (
        <article className={`rounded-lg shadow-lg overflow-hidden ${darkMode ? ' bg-black text-gray-300' : 'bg-white text-gray-900'}`}>
          <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
          <div className="p-4">
            <h1 className="text-3xl font-bold">{post.title}</h1>
            <p className="text-blue-400/50">Post By : {post.postname}</p>
            <p className="mt-4">{post.content}</p>
          </div>
        </article>
      ) : (
        <p>Post not found!</p>
      )}
    </div>
  );
};

export default SinglePost;
